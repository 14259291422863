
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


const Timeline = () => {
  return (
    
    <section class="sub-section">
    <div className="container">
    
        <div className="row g-0 wat-tech">
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 d-flex offset-xl-1 offset-lg-1 offset-md-1 ">
    
    <div class="card card-dark mt-48 w-100">
      <div class="card-body p-0">

      <h3 className='text-center'>Journey of Mechemco</h3>
      <VerticalTimeline>

<VerticalTimelineElement
    className="vertical-timeline-element--education custom-timeline-element"
    date="2021"
  >
    <h3 className="vertical-timeline-element-title">Commissioning of New plant at Patalganga</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--education custom-timeline-element"
    date="2016"
  >
    <h3 className="vertical-timeline-element-title">Combined Installed Capacity reached 25,000 TPA</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--education custom-timeline-element"
    date="2014"
  >
    <h3 className="vertical-timeline-element-title">Lloyd’s Approval for Mechster® Resins</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--education custom-timeline-element"
    date="2011"
  >
    <h3 className="vertical-timeline-element-title">ISO 9001: 2008 Certification</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--education custom-timeline-element"
    date="2009"
  >
    <h3 className="vertical-timeline-element-title">Expansion for Mechemco Resins Pvt. Ltd. to increase production capacity to ~ 18,000 TPA</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--education custom-timeline-element"
    date="2005"
  >
    <h3 className="vertical-timeline-element-title">Start-up of Mechemco Resins Pvt. Ltd. with 10,000 TPA installed capacity and commissioned with a provision to double the capacity</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--education custom-timeline-element"
    date="2003"
  >
    <h3 className="vertical-timeline-element-title">Exclusive Distributorships of different International Brands of Release Agents, Surface Tissues and Various other Accessories for Composite Industry</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--education custom-timeline-element"
    date="2000"
  >
    <h3 className="vertical-timeline-element-title">Set up of Customer Care Centre at Royal Thermoset Pvt. Ltd.</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--work custom-timeline-element"
    date="1999"
  >
    <h3 className="vertical-timeline-element-title">Exclusive Distributorship in India for Glass-Craft Inc., USA (now Graco Inc.)</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--work custom-timeline-element"
    date="1995"
  >
    <h3 className="vertical-timeline-element-title">– Launched Solid Surface Resins, Regular Exports to Southeast Asia commenced</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--work custom-timeline-element"
    date="1993"
  >
    <h3 className="vertical-timeline-element-title">First Export of Mechster® Resins</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--work custom-timeline-element"
    date="1990"
  >
    <h3 className="vertical-timeline-element-title">Introduction of Vinyl Ester Resins</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--work custom-timeline-element"
    date="1988"
  >
    <h3 className="vertical-timeline-element-title">Setting up of In-house R & D Facility at Mechemco Industries</h3>
</VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--work custom-timeline-element" 
    date="1983"
  >
    <h3 className="vertical-timeline-element-title">Mechemco Industries Established by Mr. Pradip Thakkar with a modest production capacity of 360 TPA</h3>
</VerticalTimelineElement>

</VerticalTimeline>


</div>
</div>
</div>
</div>
</div>
</section>
  )
}

export default Timeline