import CareerHeader from '../../components/career/CareerHeader';
import CareerList from '../../components/career/CareerList';
import './career.css';

const Career = () => {
  return (
    <div className="wrapper"> 
        <CareerHeader/>
        <CareerList/>
    </div>
  )
}

export default Career;