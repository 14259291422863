

import {homeSliderData} from "../../utils/homeSliderData";

// export const CarouselIndicators =(props) => {
//     const {homeSliderData} = props;
//     return(
//         <button type="button" data-bs-target="#carouselHome" data-bs-slide-to={homeSliderData.id - 1} className={`${homeSliderData.id === 1 ? 'active' : ''}`} aria-current="true" aria-label={"Slide " + homeSliderData.id}></button>
//     );
// }

const HomeSlider = () => {
  return (
    <section className="home-slider">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    
                <div id="carouselHome" className="carousel carousel-fade" data-bs-ride="carousel">
                    {/* <div className="carousel-indicators">
                       {
                            homeSliderData.map((hsindicatorlist) => (
                                <CarouselIndicators homeSliderData = {hsindicatorlist} />
                            ))
                       }
                    </div> */}
                    <div className="carousel-inner">
                        {
                            console.log('homeSliderData : ', homeSliderData)
                        }
                    
                            
                        { 

                            homeSliderData.map((hslist, index) => (  
                                <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                    <img src={require('../../assets/images/home-slider/' + hslist.hsimg)} className="d-block w-100" alt={hslist.hsalt} />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h2>{hslist.hscaptionhead}</h2>
                                        <p>{hslist.hscaptiondes}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselHome" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselHome" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default HomeSlider;