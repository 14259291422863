import React from 'react'
import ContactHeader from '../../components/contact/ContactHeader'
import {useState} from "react";
import { CONTACT_URL } from '../../utils/constants';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';



const Contact = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [designation, setDesignation] = useState('');
    const [company, setCompany] = useState('');
    const [mobNumber, setMobNumber] = useState('');
    const [emailId, setEmailId] = useState('');
    const [message, setMessage] = useState('');
    //const [errors, setErrors] = useState({});
    const [captchaToken, setCaptchaToken] = useState(null);
    const [successMessage, setSuccessMessage] = useState(''); // New state variable



    const handleSubmit= async (e)=>{
        e.preventDefault();
       /* if (!captchaToken) {
            alert("Please complete the CAPTCHA");
            return;
        }*/

        const contactform={
            firstName: firstName,
            lastName: lastName,
            designation: designation,
            company: company,
            mobile : mobNumber,
            email: emailId,
            message: message
        }
        console.log(firstName);
        console.log(contactform);
        const posturl = CONTACT_URL;
        await axios.post(posturl,{contactform})
        .then((res)=>{
            console.log(res);
            if (res.status === 200) {
                setSuccessMessage('Your message has been sent successfully!'); // Set success message
                // Reset form fields if needed
                setFirstName('');
                setLastName('');
                setDesignation('');
                setCompany('');
                setMobNumber('');
                setEmailId('');
                setMessage('');
                setCaptchaToken(null);
            }

        }).catch((err)=>{
            console.log(err);
        })
    }

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };


  return (

    
    <div className="wrapper"> 
    <ContactHeader />
    <section className="product-list-section">
            
            <div className="container">
                
                        <div className="product-main">
                            <div className="row mb-15">

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="card bg-white content h-100" >
                                        <div className="card-body p-30">
                                            <h5 className="">Head Office</h5>
                                            <p>
                                            <b>Mechemco</b><br/>
                                            27, Kewal Industrial Estate,Senapati Bapat Marg, Lower Parel,<br/>Mumbai 400013, Maharashtra, India<br/>
                                            Phone: +91-22-24940266 / 24951799 / 24922039

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-end">
                                    <div className="card bg-white content h-100" >
                                        <div className="card-body p-30">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30179.800850993754!2d72.81608609999999!3d18.998775000000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce8d1e65b791%3A0xb4f3d51f03ce123f!2sKewal%20Industrial%20Estate!5e0!3m2!1sen!2sin!4v1714799795342!5m2!1sen!2sin" width="100%" height="170"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    
                                    
                                    <div className="card bg-white content h-100" >
                                        <div className="card-body p-30">
                                        <div class="row">
                                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">

                                                <h5 className="">Plant 1</h5>
                                                <p>
                                                    <b>Mechemco Resins Pvt Ltd</b><br/>
                                                    N-33, MIDC Additional Patalganga Industrial Area, <br/>Raigad, Pin-410220, Maharashtra, India<br/>
                                                    {/* Phone: +91-22-27682720 / 27632153 / 27632154 +91-22-27682721<br/> */}
                                                    Email: info@mechemco.com

                                                </p>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-end">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3775.2699976921094!2d73.1684266!3d18.8750996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7e1dd9c62161b%3A0xf5db5ade6cf7b8dc!2sMechemco%20Resins%20Private%20Limited!5e0!3m2!1sen!2sin!4v1714803848031!5m2!1sen!2sin" width="100%" height="170"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
                                            </div>
                                            <hr />
                                           
                                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                                <h5 className="">Plant 2</h5>
                                                <p>
                                                <b>Mechemco Industries</b><br/>
                                                A-34, MIDC PHASE II, Dombivli East <br/>Pin-421204, Thane, Maharashtra, India<br/>
                                                    {/* Phone: +91-251-2871356 / 2871998 / +91-251-2871856<br/> */}
                                                    Email: info@mechemco.com

                                                </p>
                                    
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-end">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.802246197956!2d73.0964405!3d19.203837800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be795847e5c2cb3%3A0x13c252f2687554f3!2s34%2C%20Industrial%20Area%2C%20MIDC%2C%20Dombivli%20East%2C%20Kalyan%2C%20Dombivli%2C%20Maharashtra%20421203!5e0!3m2!1sen!2sin!4v1714804022639!5m2!1sen!2sin" align="right" width="100%" height="150"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                            <hr />

                                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                                <h5 className="">Plant 3</h5>
                                                <p>
                                                <b>Network Polymers Pvt. Ltd.</b><br/>
                                                N-80, MIDC Tarapur, Palghar 401506, Maharashtra, India
                                                    

                                                </p>
                                                
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-end">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3754.265356838145!2d72.7216275!3d19.7863434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be71fd516dcc613%3A0x1950f4915e16b523!2sNetwork%20Polymer!5e0!3m2!1sen!2sin!4v1714826359349!5m2!1sen!2sin" width="100%" height="90" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>

                                            <hr />

                                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                                <h5 className="">Technical centre</h5>
                                                <p>
                                                <b>Royal Thermoset Pvt. Ltd.</b><br/>
                                                C-480, MIDC, TTC Industrial Area, Pawne, Navi Mumbai-400705, Maharashtra, India
                                                    
                                                </p>
                                                
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-end">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.4276493099487!2d73.0304209!3d19.088886199999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c136b2c080cb%3A0xd1615f2f1ff5a1d4!2sROYAL%20THERMOSET%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1714826760057!5m2!1sen!2sin" width="100%" height="100" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 justify-content-end">
                                    {successMessage && (
                                    <div className="alert alert-success" role="alert">
                                        {successMessage}
                                    </div>
                                    )}

                                  
                                    <div className="card bg-white content h-100" >
                                        <div className="card-body p-30">
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div class="mb-20">
                                                            <label for="firstName" className="form-label">First Name</label>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                id="firstName"
                                                                value={firstName}
                                                                onChange={(e) => setFirstName(e.target.value)}
                                                            />

                                                            {/* {errors.firstName && <p className="text-danger">{errors.firstName}</p>} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div class="mb-20">
                                                            <label for="lastName" className="form-label">Last Name</label>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                id="lastName"
                                                                value={lastName}
                                                                onChange={(e) => setLastName(e.target.value)}
                                                            />
                                                            {/* {errors.lastName && <p className="text-danger">{errors.lastName}</p>} */}
                                                        </div>
                                                    </div>
                                            
                                                    <div className="col-6">
                                                        <div class="mb-20">
                                                            <label for="cvUpload" className="form-label">Designation</label>
                                                            <input 
                                                            type="text"                                                   
                                                            className="form-control" 
                                                            id="designation"
                                                            value={designation}
                                                            onChange={(e)=> setDesignation(e.target.value)}
                                                            />
                                                    
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div class="mb-20">
                                                            <label for="cvUpload" className="form-label">Company Name</label>
                                                            <input 
                                                            type="text"                                                   
                                                            className="form-control" 
                                                            id="company"
                                                            onChange={(e)=>setCompany(e.target.value)}
                                                            />
                                                    
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div class="mb-20">
                                                            <label for="mobNumber" className="form-label">Mobile No.</label>
                                                            <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            id="mobileNumber"
                                                            value={mobNumber}
                                                            onChange={(e) => setMobNumber(e.target.value)}
                                                            />
                                                            {/* {errors.mobNumber && <p className="text-danger">{errors.mobNumber}</p>} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div class="mb-20">
                                                            <label for="emailId" className="form-label">Email Id</label>
                                                            <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            id="emailId"
                                                            value={emailId}
                                                            onChange={(e) => setEmailId(e.target.value)}
                                                            />
                                                                {/* {errors.emailId && <p className="text-danger">{errors.emailId}</p>} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="mb-20">
                                                            <label for="cvUpload" className="form-label">Message</label>
                                                            <textarea 
                                                            type="text"                                                   
                                                            rows="3"
                                                            className="form-control" 
                                                            id="message"
                                                            value={message}
                                                            onChange={(e)=>setMessage(e.target.value)}
                                                            />
                                                            
                                                        </div>
                                                    </div>

                                                        {/*<div className="col-12">
                                                            <div className="mb-20">
                                                                <ReCAPTCHA
                                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                                    onChange={handleCaptchaChange}
                                                                />
                                                            </div>
                                                        </div>*/}


                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button class="btn btn-md btn-dark" type="submit" onClick={handleSubmit}>Send</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                  
                                </div>
                            </div>
                          
                        </div>
                    </div>
            
                
      
        
        
            
        </section>
    </div>
            
  )
}

export default Contact