const Aboutmarquee =() => {
    return(
        <section className="about-marquee">
            <div class="text_bg_animation">
                <div class="anitext">
                    <span>Leading Manufacturer and Export</span>
                    <span>Leading Manufacturer and Export</span>
                    <span>Leading Manufacturer and Export</span>
                    <span>Leading Manufacturer and Export</span>
                    <span>Leading Manufacturer and Export</span>
                    <span>Leading Manufacturer and Export</span>
                    <span>Leading Manufacturer and Export</span>
                    <span>Leading Manufacturer and Export</span>
                    <span>Leading Manufacturer and Export</span>
                    <span>Leading Manufacturer and Export</span>
                </div>
            </div>
            <div className="text_sm_am">
            Unsaturated Polyester Resins | Vinyl Ester Resins | Gelcoats | Putties | Specialty Additive Products
            </div>
        </section>
    );
}

export default Aboutmarquee;