
import {CAREER_HEAD_IMG} from '../../utils/constants';

const CareerHeader = () => {
  return (
    <section className="page-head-section pb-0">
        <div className="container">
            <div className="row">
                <div className="col-12">
                 
                    <div className="page-head">
                        <img src={require('../../assets/images/page-header/' + CAREER_HEAD_IMG)} alt="" className="img-fluid" />
                        <div className="overlay"><h1>Career</h1></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  )
}

export default CareerHeader;