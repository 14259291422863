import { useState } from "react";
import ApplyForm from "./ApplyForm";

const careerData = [
  {
    id: "0",
    designation: "Production Manager",
    description: "A Production Manager is a professional who oversees the production process and coordinates all activities to ensure enough resources on hand. They can plan workers’ schedules, estimate.",
    postedDate: "10th Jan 2024"
  },
  {
    id: "1",
    designation: "Hiring Manager",
    description: "A Hiring Manager is a professional who oversees the production process and coordinates all activities to ensure enough resources on hand. They can plan workers’ schedules, estimate.",
    postedDate: "06th Jan 2024"
  },
  {
    id: "2",
    designation: "Sales Manager",
    description: "A Sales Manager is a professional who oversees the Sales process and coordinates all activities to ensure enough resources on hand. They can plan workers’ schedules, estimate.",
    postedDate: "04th Jan 2024"
  }
];

const CareerList = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  // Function to handle button click and toggle text
  const handleButtonClick = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <section className="career-list-section negative-mt-4">
      <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="bg-light">
                    <div className="row">
                        <div className="custom-container mx-auto body-text">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {careerData.map((careerItem, index) => (
                                <div className="accordion-item mb-40 rounded-3 " key={careerItem.id}>
                                    <div
                                    className={`accordion-button collapsed rounded-3 ${activeAccordion === index ? 'active' : ''}`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#career${careerItem.id}`}
                                    aria-expanded={activeAccordion === index}
                                    aria-controls={`#career${careerItem.id}`}
                                    >
                                    <div className="row">
                                        <div className="col-12">
                                            <h3>{careerItem.designation}</h3>
                                        </div>
                                        <div className="col-md-9 col-xs-12 mt-4 mb-4">
                                            {careerItem.description}
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-4 mt-4 mb-4 align-middle">
                                            <button
                                            className="btn btn-dark float-end d-none d-md-block"
                                            onClick={() => handleButtonClick(index)}
                                            >
                                            {activeAccordion === index ? 'View less' : 'View more'}
                                            </button>
                                            <div
                                            className="d-lg-none d-sm-block align-middle"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#career${careerItem.id}`}
                                            aria-expanded="false"
                                            aria-controls={`#career${careerItem.id}`}
                                            >
                                            <a
                                            href=""
                                            className="link-underline-info small-text"
                                            onClick={() => handleButtonClick(index)}
                                            >
                                            {activeAccordion === index ? 'View less' : 'View more'}
                                            </a>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-sm-8 col-8 mt-4 mb-4 posted-date align-middle">
                                            {careerItem.postedDate}
                                        </div>
                                    </div>
                                </div>
                                <div id={`career${careerItem.id}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div className="row responsibility">
                                            <div className="col-md-6 col-sm-12">
                                                <h6>Responsibility</h6>
                                                <ul>
                                                    <li>Prep and Cook: Follow recipes to prep and cook dishes with care.</li>
                                                    <li>Keep it Clean: Maintain a tidy kitchen—clean equipment, work areas, and utensils.</li>
                                                    <li>Stock Up: Keep an eye on ingredients and let us know when we're running low.</li>
                                                    <li>Work Together: Communicate with the team and help each other out.</li>
                                                    <li>Quality Check: Make sure everything looks and tastes great before it goes out.</li>
                                                </ul>

                                                <h6 className="mt-40">Qualification</h6>
                                                <p>
                                                <ul>
                                                    <li>Experience is a bonus but not a must.</li>
                                                    <li>Know the basics of keeping food and the kitchen safe.</li>
                                                    <li>Can handle a busy kitchen and work well with others.</li>
                                                    <li>Ready to learn and take direction.</li>
                                                    <li>Be okay with standing and some heavy lifting</li>
                                                </ul>
                                                </p>
                                                <h6>Job Type :<span className="body-text"> Full-time</span></h6>
                                                <h6>Salary :<span className="body-text"> ₹10,000.00 - ₹12,000.00 per month</span></h6>
                                            </div>
                                            <div className="col-md-6 col-sm-12 mt-40">
                                                <ApplyForm />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
  );
};

export default CareerList;
