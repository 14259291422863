import React from 'react'
import { FaFilePdf } from "react-icons/fa";
import DownloadHeader from '../../components/downloads/DownloadHeader'
import { productBrochure } from '../../utils/productBrochure'

const Downloads = () => {

  return (
    <div className="wrapper"> 
        <DownloadHeader />
        <section className="product-list-section">
            <div className="container">
                <div className="product-main">
                    <div className="row mb-15 ">
                        
                            {
                                productBrochure.map((data) => {
                                    const pdfpath = require(`../../assets/images/downloads/Products_brochure/${data.p_br_pdf}`);

                                        return(

                                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mb-20">
                                                <a href={pdfpath} className='text-decoration-none text-dark text-center' target="_blank">
                                            <div className="card career-form-card content h-100 " >
                                            
                                                <div className="card-body d-flex align-items-center flex-column justify-content-center" style={{minHeight:"120px"}}>
                                                    <FaFilePdf />
                                                        <h5 className='text-center' style={{marginBottom:"0"}}> {data.brochureheading}</h5>
                                                </div>
                                            
                                            </div>
                                            </a>
                                        </div>
                                        )
                                    
                                    
                               } )       
                            }
                                
                       
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Downloads