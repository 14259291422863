const OurVision =() => {
    return(
        <section class="sub-section">
            <div className="container">
                <div className="row g-5">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <img src={require('../../assets/images/our-vision.png')} className="img-fluid" alt="" />
                        <div className="card card-light mt-48">
                            <div className="card-body">
                                <h2>Our Strengths</h2>
                                <p>
                                    Commitment to quality and consistency. <br />
                                    Commitment to Quality & Consistency Focus on Research & Innovation: Concept to Commercialization of over 150 Products. <br />
                                    Exceptional Customer Service <br />
                                    Established Global Supply Chain Infrastructure
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="card card-dark mb-48">
                            <div className="card-body">
                                <h2>Our Vision</h2>
                                <p>Support the Global Composites Industry by Continuous Research, Development and Optimization of Resin Technologies</p>
                            </div>
                        </div>
                        <img src={require('../../assets/images/our-strengths.png')} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurVision;