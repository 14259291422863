
import {Link} from "react-router-dom";
import logoimg from "../../assets/images/mechemco-logo.png";

const Header = () => {
    return (
            <header>
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <a className="navbar-brand d-md-none" href="#">
                            <img src={logoimg} className="m-logo" alt="Mechemco Logo" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                        <ul className="navbar-nav nav-left">
                            <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                            <Link className="nav-link" to="/about">About</Link>
                            </li>
                            <li className="nav-item">
                            <Link className="nav-link" to="/products">Products</Link>
                            </li>
                        </ul>

                        <ul className="navbar-nav d-none d-md-block">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">
                                    <img src={logoimg} alt="" className="d-logo" />
                                </a>
                            </li>
                        </ul>

                        <ul className="navbar-nav nav-right">
                            <li className="nav-item">
                            <Link className="nav-link" to="/downloads">Download</Link>
                            </li>
                            {/* <li className="nav-item">
                            <Link className="nav-link" to="/career">Career</Link>
                            </li> */}
                            <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact</Link>
                            </li>
                            
                        </ul>
                        </div>
                    </nav>
                </div>
            </header>
    );
}


export default Header;