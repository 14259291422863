import ClientSlider from "../../components/home/ClientSlider";
import HomeProduct from "../../components/home/HomeProduct";
import HomeSlider from "../../components/home/HomeSlider";

const Home = () => {
    return (
        <div className="wrapper">
            <HomeSlider />

            <HomeProduct />

            {/* <ClientSlider /> */}
        </div>
    );
}
export default Home;