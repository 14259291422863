export const productBrochure = [
    {
     "id": 1,
     "p_br_pdf": "Gelcoats.pdf",
     "brochureheading": "Gelcoats"
    },
    {
        "id": 2,
        "p_br_pdf": "Solid surface.pdf",
        "brochureheading": "Solid Surface Resins"
       },
       {
        "id": 3,
        "p_br_pdf": "Vinyl Ester Resins.pdf",
        "brochureheading": "Vinyl Ester Resins"
       },
       {
        "id": 4,
        "p_br_pdf": "Compression Moulding.pdf",
        "brochureheading": "Resins For Compression Moulding"
       },
       {
        "id": 5,
        "p_br_pdf": "Infusion Moulding.pdf",
        "brochureheading": "Resins For Infusion Moulding"
       },
       {
        "id": 6,
        "p_br_pdf": "LSE Resins.pdf",
        "brochureheading": "LSE Resins"
       },
       {
        "id": 7,
        "p_br_pdf": "Open Mould.pdf",
        "brochureheading": "Resins for Open Mould"
       },
       {
        "id": 8,
        "p_br_pdf": "RTM.pdf",
        "brochureheading": "Resins for RTM"
       },

       {
        "id": 9,
        "p_br_pdf": "Pultrusion application.pdf",
        "brochureheading": "Resins for Pultrusion"
       },
       {
        "id": 10,
        "p_br_pdf": "Bonding Paste.pdf",
        "brochureheading": "Bonding Paste and Corner Filling Compound"
       },
       {
        "id": 11,
        "p_br_pdf": "Filament Winding.pdf",
        "brochureheading": "Resins For Filament Winding"
       },
       {
        "id": 12,
        "p_br_pdf": "Flame Retardant.pdf",
        "brochureheading": "Flame Retardant Resins"
       },
       {
        "id": 13,
        "p_br_pdf": "Engineered Stone.pdf",
        "brochureheading": "Resins for Engineered Stone and Composite Marble"
       },
       {
        "id": 14,
        "p_br_pdf": "Mechster Tooling Brochure.pdf",
        "brochureheading": "Tooling System"
       },


   ];