import axios from "axios";
import React, { useEffect, useState } from "react";
import { REGISTER_URL } from "../../utils/constants";
import { LOGIN_URL } from "../../utils/constants";
import { jwtDecode } from "jwt-decode";


import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css'; // Ensure you import the styles


const GetOtp = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isFirstDivVisible, setIsFirstDivVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.firstName.trim()) {
      errors.firstName = "First name is required";
      isValid = false;
    }

    if (!formData.lastName.trim()) {
      errors.lastName = "Last name is required";
      isValid = false;
    }
    if (!formData.location.trim()) {
      errors.location = "location is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
      isValid = false;
    }

    if (!formData.mobile_no.trim()) {
      errors.mobile_no = "Mobile number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.mobile_no)) {
      errors.mobile_no = "Mobile number must be 10 digits";
      isValid = false;
    }

    if (!formData.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
      isValid = false;
    }

   
    setFormErrors(errors);
    return isValid;
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    location: "",
    mobile_no: "",
    email: "",
    remarks: "",
    password: "",
    username: "",
    pwd: "",
  });

  const [loginFormData, setloginFormData] = useState({
    username: "",
    pwd: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleLoginChange = (e) => {
    setloginFormData({ ...loginFormData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const posturl = REGISTER_URL;
      console.log(formData);
      try {
        const response = await axios.post(posturl, { formData });
        const { token, ...user } = response.data;

        // Save the JWT token and user data to local storage
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));

        console.log(response.data);
        console.log("here");
        checkLoginStatus();

        setOpen(false); // Close the modal
      } catch (error) {
        return error.response.json().then(errorData => {
          console.error('Error:', errorData.message);
          // Example: Update UI to show the error message
          document.getElementByClass('text-message').innerText = errorData.message;
        });
  
      }
    } else {
      console.log("Form validation failed");
    }
  };

  /*const handleloginSubmit = async (e) => {
    e.preventDefault();
    const posturl = LOGIN_URL;
    console.log(loginFormData);
    try {
      const response = await axios.post(posturl, { loginFormData });
      console.log(response.data);
      const { accessToken, ...user } = response.data;

      // Save the JWT token and user data to local storage
      localStorage.setItem("token", accessToken);
      localStorage.setItem("user", JSON.stringify(user));
      checkLoginStatus();
      setOpen(false); // Close the modal
    } catch (error) {
      console.error(error);
    }
  };*/

  
  const handleloginSubmit = async (e) => {
    e.preventDefault();
    const posturl = LOGIN_URL;
    console.log(loginFormData);
    try {
        const response = await axios.post(posturl, { loginFormData });
        const { status, data } = response;

        if (status === 200) {
            const { accessToken, ...user } = data;

            // Save the JWT token and user data to local storage
            localStorage.setItem("token", accessToken);
            localStorage.setItem("user", JSON.stringify(user));
            checkLoginStatus();
            setOpen(false); // Close the modal
            // Optionally, show a success message
            alert("Login successful!");
        } else {
            // Handle other status codes
            console.log(`Unexpected status code: ${status}`);
            alert(`Unexpected status code: ${status}`);
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const { status, data } = error.response;

            console.error(`Error: ${status}`, data);

            if (status === 400) {
                alert("Bad Request: " + data.message);
            } else if (status === 401) {
                alert("Unauthorized: " + data.message);
            } else if (status === 500) {
                alert("Internal Server Error: Please try again later.");
            } else {
                alert(`Error: ${status} - ${data.message}`);
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.error("Error: No response received", error.request);
            alert("No response from server. Please try again later.");
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error", error.message);
            alert("An unexpected error occurred: " + error.message);
        }
    }
};


  const isTokenValid = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;

    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (error) {
      return false;
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsLoggedIn(false);
    // Redirect to login page or home page
    window.location.href = "/"; // Adjust the path as needed
  };

  useEffect(() => {
    checkLoginStatus();
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(logoutUser, 2 * 60 * 1000); // 5 minutes
    };

    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Set the initial timer
    resetTimer();

    return () => {
      clearTimeout(timer);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, []);

  // Function to check login status
  const checkLoginStatus = () => {
    if (isTokenValid()) {
      setIsLoggedIn(true);
      console.log("User is logged in");
    } else {
      setIsLoggedIn(false);
      console.log("User is not logged in or token has expired");
    }
  };

  // Function to toggle the visibility
  const toggleDivs = () => {
    setIsFirstDivVisible(!isFirstDivVisible);
  };

// responsive modal start
  const [open, setOpen] = React.useState(false);

  

  return (
  <>
    <Modal open={open} onClose={() => setOpen(false)}>
      
      
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {" "}
              {isFirstDivVisible ? "Login" : "Sign Up"}
            </h1>
            
          </div>
          <div className="modal-body">
            {/* OTP FORM Starts */}
            {isFirstDivVisible && (
              <form onSubmit={handleloginSubmit}>
                <div className="card career-form-card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div class="mb-20">
                          <label for="username" className="form-label">
                            Enter Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            value={loginFormData.username}
                            onChange={handleLoginChange}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-20">
                          <label for="pwd" className="form-label">
                            Enter Password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="pwd"
                            value={loginFormData.otpNo}
                            onChange={handleLoginChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <button className="btn btn-md btn-dark" type="submit">
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                toggleDivs();
              }}
            >
              {isFirstDivVisible ? "Sign Up" : "Login"}
            </a>

            {/* Signup form start */}
            {!isFirstDivVisible && (
              <div className="card career-form-card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="mb-20">
                          <label htmlFor="firstName" className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                          />
                          {formErrors.firstName && (
                            <div className="">{formErrors.firstName}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="mb-20">
                          <label htmlFor="lastName" className="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                          />
                          {formErrors.lastName && (
                            <div className="">{formErrors.lastName}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-20">
                          <label htmlFor="companyName" className="form-label">
                            Company Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                          />
                          {formErrors.companyName && (
                            <div className="">{formErrors.companyName}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-20">
                          <label htmlFor="location" className="form-label">
                            Location
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="location"
                            value={formData.location}
                            onChange={handleChange}
                          />
                          {formErrors.location && (
                            <div className="">{formErrors.location}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-20">
                          <label htmlFor="mobile_no" className="form-label">
                            Mobile No.
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="mobile_no"
                            value={formData.mobile_no}
                            onChange={handleChange}
                          />
                          {formErrors.mobile_no && (
                            <div className="">{formErrors.mobile_no}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-20">
                          <label htmlFor="email" className="form-label">
                            Email Id
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                          {formErrors.email && (
                            <div className="">{formErrors.email}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-20">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="password"
                            value={formData.password}
                            onChange={handleChange}
                          />
                          {formErrors.password && (
                            <div className="">{formErrors.password}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-20">
                          <label htmlFor="remarks" className="form-label">
                            Remarks
                          </label>
                          <textarea
                            rows="3"
                            className="form-control"
                            id="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                          />
                          {formErrors.remarks && (
                            <div className="">{formErrors.remarks}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-md btn-dark" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
         
        </div>
      </div>
    
      </Modal>

</>



  );
};

export default GetOtp;
