export const clientsData = [
    {
     "id": "1",
     "cimg": "cl1.jpg"
    },
    {
    "id": "2",
     "cimg": "cl2.jpg"
    },
    {
    "id": "3",
     "cimg": "cl3.jpg"
    },
    {
    "id": "4",
     "cimg": "cl4.jpg"
    },
    {
    "id": "5",
     "cimg": "cl5.jpg"
    }
   ];