import AboutHeader from "../../components/about/AboutHeader";
import Aboutmarquee from "../../components/about/Aboutmarquee";
import OurVision from "../../components/about/OurVision";
import Timeline from "../../components/about/Timeline";
import WeAreTechnology from "../../components/about/WeAreTechnology";


const About =() => {
    return(
        <div className="wrapper"> 
            <AboutHeader />
            <WeAreTechnology />
            <Aboutmarquee />
            <OurVision />
            <Timeline />
        </div>
    );
}

export default About;