export const homeSliderData = [
    {
     "id": 1,
     "hsimg": "banner_train.jpg",
     "hsalt": "Mechemco 1",
     "hscaptionhead": "Technology Driven Group",
     "hscaptiondes": "Mechemco is a leading manufacturer of Composites Resins including Unsaturated Polyester & Vinyl Ester with 3 plants in India operating with state of the art technology."
    },
    {
     "id": 2,
     "hsimg": "banner_ship.jpg",
     "hsalt": "Mechemco 2",
     "hscaptionhead": "Catering to wide range of Processes & Applications",
     "hscaptiondes": "Our products are suitable for all composites processes and we cater to a wide range of applications including chemical equipment, transport, energy, marine, industrial, electrical, healthcare, etc."
    },
    {
     "id": 3,
     "hsimg": "banner_bus.jpg",
     "hsalt": "Mechemco 3",
     "hscaptionhead": "Focused on Research & Development",
     "hscaptiondes": "With a team of leading scientists and engineers with a rich industry experience, we are focused on R&D and product development and have developed all of our 300 + products in-house."
    },
    {
     "id": 4,
     "hsimg": "banner_wind_fan.jpg",
     "hsalt": "Mechemco 4",
     "hscaptionhead": "Commitment to Quality & Consistency",
     "hscaptiondes": "Mechemco has a strong production & quality system with a well trained and qualified quality control team and certifications like ISO 9001 & Lloyd's Register of Shipping."
    },

    {
        "id": 4,
        "hsimg": "banner_oil.jpg",
        "hsalt": "Mechemco 5",
        "hscaptionhead": "Technical Support",
        "hscaptiondes": "Our customers are our partners and we support them technically in process improvements, product development and troubleshooting"
       }
   ];