
import GetOtp from "../../components/products/GetOtp";
import ProductsHeader from "../../components/products/ProductsHeader";
import ProductsList from "../../components/products/ProductsList";

const Products =() => {
    return(
        <>
        <div className="wrapper">
            <ProductsHeader />
            <ProductsList />
        </div>
        <GetOtp />
        </>
    );
}


export default Products;