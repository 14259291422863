
import {ABOUT_HEAD_IMG} from '../../utils/constants';

export const AboutHeader = () => {
  return (
    <section className="page-head-section">
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <div className="page-head">
                      <img src={require('../../assets/images/page-header/' + ABOUT_HEAD_IMG)} alt="" className="img-fluid" />
                      <div className="overlay"><h1>About Us</h1></div>
                  </div>
              </div>
          </div>
      </div>
    </section>
  )
}


export default AboutHeader;