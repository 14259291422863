const WeAreTechnology =() => {
    return(
        <section class="sub-section">
            <div className="container">
                <div className="row g-0 wat-tech">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 d-flex">
                        <div className="card">
                            <div className="card-body">
                                <h2>We are Technology Driven Group</h2>
                                <p>Founded in <strong>1983</strong> by Technocrats. With <strong>90+</strong> Years of experience in the field of Process Engineering, Polymer Materials Science and Technology and Composites.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                        <img src={require('../../assets/images/we-are-tech.png')} alt="" className="img-fluid" />
                    </div>
                    </div>
                </div>
        </section>
    );
}


export default WeAreTechnology;