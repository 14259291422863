import React from 'react'
import {useState} from "react";

const ApplyForm = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [pdf, setPdf] = useState('');
    const [mobNumber, setMobNumber] = useState('');
    const [emailId, setEmailId] = useState('');
    const [errors, setErrors] = useState({});


// cv upload starts
   
    const handleCvUpload = (e) => {
        const pdfFile = e.target.files[0];
        setPdf(pdfFile);
    };

    // cv upload Ends

   
    const validateForm = () => {
        const errors = {};

        if (firstName.trim().length < 4) {
            errors.firstName = 'First Name must be at least 4 characters long';
        }

        if (lastName.trim().length < 4) {
            errors.lastName = 'Last Name must be at least 4 characters long';
        }

        if (mobNumber.length < 10) {
            errors.mobNumber = 'Mobile Number must be at least 10 characters long';
        }

        if (emailId.length < 10) {
            errors.emailId = 'Please enter a valid Email Id';
        }
       

        if (!pdf) {
            errors.pdf = 'Please upload a pdf or word file';
        }

        
        setErrors(errors);

        return Object.keys(errors).length === 0;
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            console.log("Form Submitted with Values:", {
                firstName,
                lastName,
                mobNumber,
                emailId,                
                pdf,
            });
        } else {
            console.log("Form has some errors. please check and fix");
        }
    };

  return (
    <form onSubmit={handleSubmit}>
    <div className="card career-form-card">
    <div className="card-body">
        <div className="row">
            <div className="col-md-6 col-sm-12">
                <div class="mb-20">
                    <label for="firstName" className="form-label">First Name</label>
                    <input 
                    type="text" 
                    className="form-control" 
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    />

                    {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div class="mb-20">
                    <label for="lastName" className="form-label">Last Name</label>
                    <input 
                    type="text" 
                    className="form-control" 
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    />
                    {errors.lastName && <p className="text-danger">{errors.lastName}</p>}
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div class="mb-20">
                    <label for="mobNumber" className="form-label">Mobile No.</label>
                    <input 
                    type="text" 
                    className="form-control" 
                    id="mobileNumber"
                    value={mobNumber}
                    onChange={(e) => setMobNumber(e.target.value)}
                    />
                    {errors.mobNumber && <p className="text-danger">{errors.mobNumber}</p>}
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div class="mb-20">
                    <label for="emailId" className="form-label">Email Id</label>
                    <input 
                    type="text" 
                    className="form-control" 
                    id="emailId"
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    />
                    {errors.emailId && <p className="text-danger">{errors.emailId}</p>}
                </div>
            </div>
            <div className="col-12">
                <div class="mb-20">
                    <label for="cvUpload" className="form-label">Upload Resume</label>
                    <input 
                    type="file"
                    accept=".pdf, .docx, .doc"
                    onChange={handleCvUpload}
                    className="form-control" id="cvUpload"
                    />

                    {errors.emailId && <p className="text-danger">{errors.emailId}</p>}
                    
                </div>
            </div>

            <div className="col-12 col-sm-12">
                <div class="mb-20">
                    <label for="captcha" className="form-label">Enter Captcha</label>
                    <input type="captcha" className="form-control" id="captcha"/>
                </div>
            </div>

        </div>
        <div className="row">
            <div className="col-12">
            <button class="btn btn-md btn-dark" type="submit">Apply</button>
            </div>
        </div>
    </div>

</div>
</form>
  )
  
}

export default ApplyForm

